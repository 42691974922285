@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

html, body {
  width: 100%;
  height: 100vh;
  font-family: "Times New Roman", Times, serif;
}

.bg-main-blue {
  background-color: #3d507dff;
}

.text-main-blue {
  color: #3d507dff;
}

.bg-dark-blue {
  background-color: #2e3c5eff;
}

.text-dark-blue {
  color: #2e3c5eff;
}

.bg-main-black {
  background-color: #0d1a3aff;
}

.text-main-black {
  color: #0d1a3aff;
}

.bg-dark-black {
  background-color: #0b152fff;
}

.text-dark-black {
  color: #0b152fff;
}

.bg-main-red {
  background-color: #9d1009ff;
}

.text-main-red {
  color: #9d1009ff;
}

.bg-dark-red {
  background-color: #3d0b10ff;
}

.text-dark-red {
  color: #3d0b10ff;
}

.bg-main-white {
  background-color: #dcdeeaff;
}

.text-main-white {
  color: #dcdeeaff;
}

.bg-dark-white {
  background-color: #c7caddff;
}

.text-dark-white {
  color: #c7caddff;
}

.small-text {
  @apply text-[12pt] p-0 m-0;
}

.magnify {
  width: 200px;;
  height: 200px;
  background-size: 800%;
  background-position: center;
  @apply absolute bg-no-repeat border-2 border-white;

}



.admin-button {
  @apply min-w-[100px] max-w-[150px] text-white cursor-pointer p-1 flex justify-center items-center align-middle h-[30px] text-[10pt] rounded-sm border-[1px] shadow-sm;

}
/* CSS para estilizar a barra de scroll */
::-webkit-scrollbar {
  width: 8px; /* largura da barra de scroll */
}

::-webkit-scrollbar-track {
  background: #ffffff; /* cor de fundo da trilha do scroll */
}

::-webkit-scrollbar-thumb {
  background: #888; /* cor do indicador da barra de scroll */
  border-radius: 4px; /* borda arredondada */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* cor do indicador ao passar o mouse */
}

.no-scrollbar {
  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  -webkit-overflow-scrolling: touch; /* Optional for smoother scrolling on iOS */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

/* Hide scrollbar for Firefox */
.no-scrollbar {
  scrollbar-width: none; /* Hide scrollbar */
}

/* Hide scrollbar for Microsoft Edge */
.no-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar */
}

 .archivo-black-regular {
    font-family: "Archivo Black", sans-serif;
    font-weight: 900;
    font-style: normal;
    @apply text-slate-200;
  }
  .archivo-black-regular2 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 900;
    font-style: normal;
    @apply text-blue-600;
  }
  .archivo-black-regular3 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 900;
    font-style: normal;
    @apply text-slate-700;
  }
  .archivo-black-regular4 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 900;
    font-style: normal;
    @apply text-yellow-400;
  }

  .archivo-black-titles {
    font-family: "Archivo Black", sans-serif;
    font-weight: 500;
    font-style: normal;
    @apply text-slate-100;
  }

  .archivo-black-mainprice {
    font-family: "Archivo Black", sans-serif;
    font-weight: 900;
    font-style: normal;
    @apply text-yellow-500;
  }

  .aspect-ratio-box {
    position: relative;
    padding-bottom: 100%;  /* Maintains 1:1 aspect ratio */
  }
  
  .aspect-ratio-box::after {
    content: "";
    display: block;
    padding-top: calc(100% / (320px / 380px));  /* Adjust based on your desired image width and container height */
  }